@if (notificationsState$ | async; as notificationsState) {
    <div class="dialog dialog_blue">
        <nn-dialog-title class="dialog__header dialog__header_big"
            [title]="'news_dialog|title' | localization"/>
        <div class="dialog__content"
            mat-dialog-content>
            @if (!notificationsState.isLoading) {

                @if (!notificationsState.isEmpty) {

                    <div class="dialog__scroll">
                        <div class="dialog__inner">
                            <ul class="news-list">
                                @for (notification of notificationsState.notifications; track notification) {
                                    <li class="news-list__item">
                                        <div class="news-item">
                                            <button class="news-item__content"
                                                (click)="onNotificaitonClick(notification)">
                                                <div class="news-item__title">
                                                    <span>{{ notification.title }}</span>
                                                    @if (notification.isNew) {
                                                        <span class="badge badge_green">{{ 'news_dialog|new' | localization }}</span>
                                                    }
                                                </div>
                                                <div class="news-item__text">{{ notification.body }}</div>
                                            </button>
                                            <div class="news-item__media">
                                                <button class="news-item__circle"
                                                    (click)="onNotificaitonClick(notification)">
                                                    @if (notification.icon) {
                                                        <img class="news-item__img"

                                                            [src]="notification.icon"
                                                            alt="notification-image"/>
                                                    }
                                                    @if (!notification.icon) {
                                                        <img class="news-item__img"

                                                            src="/assets/img/notifications/default-icon.png"
                                                            alt="notification-image"/>
                                                    }
                                                </button>
                                                <button class="news-item__button"
                                                    (click)="onArchive(notification, $event)">
                                                    <mat-icon class="mat-icon_checkmark"
                                                        svgIcon="sprite:icon-checkmark"/>
                                                </button>
                                            </div>
                                        </div>
                                    </li>
                                }
                            </ul>
                        </div>
                    </div>

                } @else {

                    <div class="dialog__scroll">
                        <div class="dialog__inner">
                            <div class="news-list__empty">{{ 'news_dialog|empty' | localization }}</div>
                        </div>
                    </div>

                }

            } @else {

                <mat-progress-spinner class="notifications-loading"
                    diameter="50"
                    mode="indeterminate"
                    strokeWidth="5"/>

            }
        </div>
        <div class="dialog__footer">
            <div class="news-list__clear">
                <button class="button-icon button-icon_small"
                    [disabled]="notificationsState.isLoading || notificationsState.isEmpty"
                    (click)="archiveAll()"
                    matRipple>
                    <div class="button-icon__media">
                        <mat-icon class="mat-icon_checkmark"
                            svgIcon="sprite:icon-checkmark"/>
                    </div>
                    <div class="button-icon__content">{{ 'news_dialog|clear' | localization }}</div>
                </button>
            </div>
            <div class="news-list__settings">
                <button class="button-icon button-icon_small"
                    [routerLink]="['/account/preferences']"
                    matRipple>
                    <div class="button-icon__media">
                        <mat-icon class="mat-icon_settings"
                            svgIcon="sprite:icon-settings"/>
                    </div>
                    <div class="button-icon__content">{{ 'news_dialog|settings' | localization }}</div>
                </button>
            </div>
        </div>
    </div>
}


