import {Notification} from '../../../core/notifications/models/notification';
import {ChangeDetectionStrategy, Component, ElementRef, Inject, ViewChild} from '@angular/core';
import {NotificationsStateViewModel} from './models/notifications-state-view-model';
import {NotificationsService} from '../../../core/notifications/services/notifications-service';
import {notificationsPanelConfig} from './notifications-panel-config';
import {Observable} from 'rxjs';
import {MatDialogConfig, MatDialogContent, MatDialogRef} from '@angular/material/dialog';
import {map, shareReplay, tap} from 'rxjs/operators';
import {NgForm} from '@angular/forms';
import {payloadKeys} from '../../../core/notifications/helpers/payload-keys';
import {Router, RouterLink} from '@angular/router';
import {TrackingNewNotificationsService} from '../../../core/notifications/services/tracking-new-notifications.service';
import {NotificationViewModel} from './models/notification-view-model';
import {APP_CONFIG, AppConfig} from '../../../infrastructure/app-config/AppConfig';
import {WINDOW_TOKEN} from '../../../infrastructure/dom/WINDOW_TOKEN';
import {NotificationRoutingService} from '../../../core/notifications/services/notification-routing.service';
import {AsyncPipe} from '@angular/common';
import {DialogTitleComponent} from '../../../shared/components/dialog-header/component';
import {LocalizationPipe} from '../../../infrastructure/localization/localization.pipe';
import {MatIcon} from '@angular/material/icon';
import {MatRipple} from '@angular/material/core';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {FrontendRouteType} from '../../../core/notifications/api/notifications-api-client';

@Component({
    templateUrl: 'component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['component.sass'],
    providers: [TrackingNewNotificationsService],
    standalone: true,
    imports: [
        AsyncPipe,
        DialogTitleComponent,
        LocalizationPipe,
        MatDialogContent,
        MatIcon,
        RouterLink,
        MatRipple,
        MatProgressSpinner,
    ],
})
export class NotificationsPanelComponent {
    public static dialogConfig: MatDialogConfig = notificationsPanelConfig;

    private static SortByCreateDate(a: Notification, b: Notification): number {
        return new Date(b.createDate).getTime() - new Date(a.createDate).getTime();
    }

    @ViewChild('form', {static: false}) public form: NgForm;
    @ViewChild('dialog', {static: false}) public dialogElm: ElementRef;
    public notificationsState$: Observable<NotificationsStateViewModel>;

    constructor(
        public readonly notificationsService: NotificationsService,
        public readonly dialogRef: MatDialogRef<any>,
        private readonly notificationRoutingService: NotificationRoutingService,
        private readonly router: Router,
        private readonly trackingNewNotificationsService: TrackingNewNotificationsService,
        @Inject(APP_CONFIG) private readonly appConfig: AppConfig,
        @Inject(WINDOW_TOKEN) private readonly window: Window,
    ) {
        this.notificationsState$ = notificationsService.state$.pipe(
            tap((state) => trackingNewNotificationsService.trackRange(state.notifications)),
            map(state => new NotificationsStateViewModel(
                    state.notifications
                        .sort(NotificationsPanelComponent.SortByCreateDate)
                        .map(notification => ({
                            ...notification,
                            hovered: false,
                            icon: !!notification.iconUrl
                                ? `${this.appConfig.fileHostingEndpoint}/${notification.iconUrl}`
                                : undefined,
                            isNew: trackingNewNotificationsService.tracked(notification),
                        })),
                    state.isLoading,
                ),
            ),
            tap(() => this.notificationsService.markAllAsRead()),
            shareReplay({bufferSize: 1, refCount: true}),
        );
    }

    public archiveAll(): void {
        this.trackingNewNotificationsService.untrackAll();
        this.notificationsService.archiveAll();
    }

    public onArchive(notification: NotificationViewModel, $event: Event): void {
        this.trackingNewNotificationsService.untrack(notification);
        this.notificationsService.archive(notification.notificationId);
        $event.preventDefault();
    }

    public onNotificaitonClick(notification: NotificationViewModel): void {
        if (this.trackingNewNotificationsService.untrack(notification)) {
            notification.isNew = false;
        }

        if (this.window &&
            notification.routeType === FrontendRouteType.Undefined &&
            notification.routeUrl) {
            this.window.open(notification.routeUrl, '_blank');
            return;
        }

        this.notificationRoutingService.navigate(notification.routeType,
            notification.payloadKey as typeof payloadKeys[number],
            notification.payloadJson,
        ).subscribe();
    }
}
