import {DestroyRef, inject, Injectable} from '@angular/core';
import {MonolithApiClient, ServiceFeatures} from './api/monolith-api-client';
import {Observable} from 'rxjs';
import {IdentityService} from '../../users/core/identity.service';
import {filter, map, switchMap, take} from 'rxjs/operators';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

@Injectable({
    providedIn: 'root',
})
export class CompletedFeaturesService {
    private readonly api = inject(MonolithApiClient);
    private readonly identityService = inject(IdentityService);
    private readonly destroyRef = inject(DestroyRef);

    public get(): Observable<ServiceFeatures[]> {
        return this.getCurrentUserId()
            .pipe(
                switchMap((userId) => this.api.customersCompletedFeatures_Get(userId)),
                takeUntilDestroyed(this.destroyRef),
            );
    }

    public complete(feature: ServiceFeatures): Observable<void> {
        return this.getCurrentUserId()
            .pipe(
                switchMap((userId) => this.api.customersCompletedFeatures_Complete(userId, feature)),
                takeUntilDestroyed(this.destroyRef),
            );
    }

    private getCurrentUserId() {
        return this.identityService.stateChanges
            .pipe(
                map((authState) => authState.user?.Id),
                filter(userId => !!userId),
                take(1),
                takeUntilDestroyed(this.destroyRef),
            );
    }
}
